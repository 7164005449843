//
// light-mode.scss
//

// ================================ //
// ======== Custom Variable ======= //
// ================================ //

:root {
  --#{$prefix}logo-lg-height: 18px;
  --#{$prefix}logo-sm-height: 22px;

  --#{$prefix}leftbar-width: 50px;
  --#{$prefix}leftbar-width-md: 50px;
  //   --#{$prefix}leftbar-width: 240px;
  //   --#{$prefix}leftbar-width-md: 160px;
  --#{$prefix}leftbar-width-sm: 70px;
  --#{$prefix}leftbar-condensed-height: 1500px;

  --#{$prefix}topbar-height: 70px;

  --#{$prefix}menu-item-icon-size: 1.1rem;
  --#{$prefix}menu-item-icon-width: 40px;
  --#{$prefix}menu-item-font-size: 0.925rem;
  --#{$prefix}menu-item-padding-x: 10px;
  --#{$prefix}menu-item-padding-y: 10px;

  // Footer Height
  --#{$prefix}footer-height: 60px;

  // Card Border Width
  --#{$prefix}theme-card-border-width: 0px;
}

// ================================ //
// ========== Main Menu =========== //
// ================================ //

// Background Light left-sidebar
html[data-menu-color='light'] {
  --#{$prefix}menu-bg: #ffffff;
  --#{$prefix}menu-item-color: #{$gray-700};
  --#{$prefix}menu-item-hover-color: #{$primary};
  --#{$prefix}menu-item-active-color: #{$primary};
  --#{$prefix}menu-item-active-bg: #{rgba($primary, 0.07)};
  --#{$prefix}menu-condensed-link-bg: #313a46;
}

// Dark Left Sidebar
html[data-menu-color='dark'] {
  --#{$prefix}menu-bg: #333;
  --#{$prefix}menu-item-color: #ccc;
  --#{$prefix}menu-item-hover-color: #1d8acb;
  --#{$prefix}menu-item-active-color: #1d8acb;
  --#{$prefix}menu-item-active-bg: #{rgba($white, 0.07)};
  --#{$prefix}menu-condensed-link-bg: #162339;
}

// Dark Mode Left Sidebar
html[data-bs-theme='dark'][data-menu-color='light'],
html[data-bs-theme='dark'][data-menu-color='dark'] {
  --#{$prefix}menu-bg: #313a46;
  --#{$prefix}menu-item-color: #8391a2;
  --#{$prefix}menu-item-hover-color: #bccee4;
  --#{$prefix}menu-item-active-color: #ffffff;
  --#{$prefix}menu-item-active-bg: #{rgba($white, 0.07)};
  --#{$prefix}menu-condensed-link-bg: #313a46;
}

// ================================ //
// ========== Topbar ============== //
// ================================ //

// Light Topbar
html[data-topbar-color='light'] {
  --#{$prefix}topbar-bg: #ffffff;
  --#{$prefix}topbar-item-color: #{$gray-700};
  --#{$prefix}topbar-item-hover-color: #{$primary};
  --#{$prefix}topbar-search-bg: #{tint-color($gray-200, 10%)};
}

// Dark Topbar
html[data-topbar-color='dark'] {
  --#{$prefix}topbar-bg: #999999e6;
  --#{$prefix}topbar-item-color: #8391a2;
  --#{$prefix}topbar-item-hover-color: #bccee4;
  --#{$prefix}topbar-search-bg: #464f5b;
}

// Dark Mode Topbar
html[data-bs-theme='dark'][data-topbar-color='light'],
html[data-bs-theme='dark'][data-topbar-color='dark'] {
  --#{$prefix}topbar-bg: #2f3742;
  --#{$prefix}topbar-item-color: #8391a2;
  --#{$prefix}topbar-item-hover-color: #bccee4;
  --#{$prefix}topbar-search-bg: #363f4a;
}
