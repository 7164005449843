//
// social.scss
//

.social-list-item {
    height: 2rem;
    width: 2rem;
    line-height: calc(2rem - 4px);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 2px solid var(--#{$prefix}gray-500);
    border-radius: 50%;
    color: var(--#{$prefix}gray-500);

    &:hover {
        color: var(--#{$prefix}gray-600);
        border-color: var(--#{$prefix}gray-600);
    }
}