@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap');

//Core files

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

@import 'config/variables';
@import 'config/variables-custom';
@import 'config/variables-dark';
@import 'config/theme-mode';
@import 'custom/components/utilities';
@import '~bootstrap/scss/bootstrap';
@import 'icons';

// Structure
@import 'custom/structure/sidenav';
@import 'custom/structure/topbar';
@import 'custom/structure/right-sidebar';
@import 'custom/structure/page-head';
@import 'custom/structure/footer';
// Horizontal nav
@import 'custom/structure/horizontal-nav';

// Components
@import 'custom/components/root';
@import 'custom/components/accordions';
@import 'custom/components/alert';
@import 'custom/components/avatar';
@import 'custom/components/breadcrumb';
@import 'custom/components/buttons';
@import 'custom/components/badge';
@import 'custom/components/card';
@import 'custom/components/custom-forms';
@import 'custom/components/dropdown';
@import 'custom/components/forms';
@import 'custom/components/modal';
@import 'custom/components/nav';
@import 'custom/components/pagination';
@import 'custom/components/popover';
@import 'custom/components/print';
@import 'custom/components/progress';
@import 'custom/components/reboot';
@import 'custom/components/tables';
@import 'custom/components/type';
@import 'custom/components/tooltip';
@import 'custom/components/widgets';
@import 'custom/components/social';
@import 'custom/components/steps';
@import 'custom/components/preloader';
@import 'custom/components/dashboard-cards';

//Pages
@import 'custom/pages/authentication';
@import 'custom/pages/components-demo';
@import 'custom/pages/error';
@import 'custom/pages/faq';
@import 'custom/pages/maintenance';
@import 'custom/pages/tasks';
@import 'custom/pages/email';
@import 'custom/pages/profile';
@import 'custom/pages/timeline';
@import 'custom/pages/pricing';

// Plugins
@import 'custom/plugins/apexcharts';
@import 'custom/plugins/calendar';
@import 'custom/plugins/chartjs';
@import 'custom/plugins/datatable';
@import 'custom/plugins/daterange';
@import 'custom/plugins/datepicker';
@import 'custom/plugins/flatpickr';
@import 'custom/plugins/dragula';
@import 'custom/plugins/dropzone';
@import 'custom/plugins/form-wizard';
@import 'custom/plugins/maps';
@import 'custom/plugins/quill-editor';
@import 'custom/plugins/select2';
@import 'custom/plugins/simplebar';
@import 'custom/plugins/toaster';
@import 'custom/plugins/bootstrap-touchspin';
@import 'custom/plugins/bootstrap-timepicker';
@import 'custom/plugins/typehead';
@import 'custom/plugins/sparklines';
@import 'custom/plugins/timepicker';
@import 'custom/plugins/rateit';
@import 'custom/plugins/responsive-table';
@import 'custom/plugins/ion-rangeslider';
@import 'custom/plugins/cropper';
