::-webkit-scrollbar {
  width: 0.5em;
  height: 0.5em;
}

::-webkit-scrollbar-thumb {
  background: #777;
}

::-webkit-scrollbar-track {
  background: #ccc;
}
.card {
  border: 1px solid #eee !important;
  padding: 0 20px;
}

.navbar-custom {
  padding-right: 20px;
  height: 51px;
  min-height: 51px;
  background: rgba(153, 153, 153, 0.9);
  border-bottom: 1px solid #f7cd63;
  display: flex;
  align-items: center;
  .breadcrumb {
    padding: 10px 0;
  }
  .topbar-menu .nav-link {
    color: #fff;
    font-weight: 100;
  }
}
.select2-container--krajee-bs5 .select2-selection,
.upload-kit .upload-kit-input,
.upload-kit .upload-kit-item,
.bootstrap-switch.bootstrap-switch-on .bootstrap-switch-label,
.bootstrap-switch.bootstrap-switch-inverse.bootstrap-switch-off
  .bootstrap-switch-label,
.bootstrap-switch {
  border-radius: 0 !important;
}

.leftside-menu .logo {
  padding: 5px;
  line-height: 40px;
}

#leftside-menu-container {
  overflow-y: auto;
  overflow-x: clip;
}
.side-nav-second-level {
  padding: 0;
}
.leftside-menu {
  width: 51px;
  .badge {
    display: none;
  }
  .side-nav-link {
    padding: 10px 3px;
    transition: all 0.5s ease;
    span {
      opacity: 0;
      visibility: hidden;
      transition: all 0.5s ease;
    }
  }
  .side-nav-second-level a {
    span {
      opacity: 0;
      visibility: hidden;
      transition: all 0.5s ease;
    }
  }

  .side-nav-title {
    font-weight: 100;
    transition: all 0.4s ease;
    padding: 0;
    font-size: 0;
    overflow: hidden;
    color: transparent;
    height: 4px;
    background: rgba(0, 0, 0, 0.2);
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3);
  }
  .menu-arrow {
    opacity: 0;
    visibility: hidden;
  }
  &:hover {
    width: 240px;
    .side-nav-link {
      padding: 10px;
      span {
        opacity: 1;
        visibility: visible;
      }
    }
    .menu-arrow {
      opacity: 1;
      visibility: visible;
    }

    .side-nav-second-level a {
      span {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  .side-nav .menuitem-active > a {
    background: transparent;
  }
}
.table {
  thead {
    th {
      font-size: 13px;
      font-weight: 700;
      font-family: 'Open Sans', 'Helvetic';
      text-transform: capitalize;
      color: #666;
      a {
        color: #666;
      }
    }
  }
  tbody {
    td {
      font-size: 13px;
      font-weight: 100;
    }
  }
}
.pagination {
  .page-item .page-link {
    font-size: 13px;
    border: none;
    font-weight: 400;
    text-transform: capitalize;
    margin-right: 1px;
    margin-bottom: 2px;
    margin-top: 2px;
    padding: 0.3em 0.5em;
  }
  .page-link.active,
  .active > .page-link {
    background: #3a3a3a;
    color: #fff;
    border: solid 1px #3a3a3a;
  }
}

.tox.tox-tinymce {
  border-radius: 0;
  border: var(--tz-border-width) solid #ddd;
}

.dashboard-cards {
  .card-header h3 {
    color: #666;
    font-weight: 400 !important;
    font-size: 12px;
    margin: 0 !important;
    line-height: 50px;
    text-transform: uppercase;
  }
}

.list-item {
  padding-left: 30px;
  position: relative;
  list-style-type: none;
}
.list-item > a:before {
  align-items: center;
  color: #fff;
  display: flex;
  justify-content: center;
  line-height: 0;
  border-radius: 50%;
  content: '+';
  font-size: 14px;
  height: 12px;
  transform: rotate(0deg) translate(8px, 8px);
  width: 12px;
  background-color: var(--tz-success);
  border: 0;
  left: 0;
  padding: 0;
  position: absolute;
  top: -3px;
}
